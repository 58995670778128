import React from 'react';
import { useState } from 'react';
import Cookies from 'js-cookie';


function LSOverlay() {
  const [ hideMsg, setHideMsg ] = useState(Cookies.get('hideLsMsg') === '1');

  if (hideMsg) {
    return null;
  }

  return (
    <article className="p-2 bg-opacity-50 bg-black fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center" style={{ zIndex: 100 }}>
      <div className="p-10 md:max-w-4xl lg:max-w-6xl max-h-full rounded-lg bg-main-bg border-2 border-gray-400 flex flex-col">
        <p className="text-5xl mb-7">Dear Players,</p>
        <div className="overflow-y-auto flex-grow">
          <p className="text-4xl mb-5">We regret to inform you that our gaming platform will no longer support real money deposits starting from September 19, 2024. If you have a balance on your account, you can still use it to play until November 1, 2024., withdrawals will be possible until October 30, 2024.</p>
          <p className="text-4xl mb-5">We want to express our heartfelt gratitude for your loyalty and support over the years so we arranged special awards and bonuses on our family-friendly casino Lucky Star. Our team is here to assist you every step of the way, we are committed to making this transition as smooth as possible for you.</p>
          <p className="text-4xl mb-5">Your 777Bay team.</p>
        </div>
        <div className="flex justify-center items-center flex-wrap pt-5">
          <span className="button big secondary mx-5 my-3" onClick={() => {
            Cookies.set('hideLsMsg', '1', { expires: 1 });
            setHideMsg(true);

            return false;
          }}>
            Close
          </span>
          <a className="button big primary mx-5 my-3" href="https://www.luckystar.io/blog/welcome-to-lucky-star?a_aid=66f2ee9966220&a_bid=e7658fe3">
            Join Lucky Star
          </a>
        </div>
      </div>
    </article>
  );
}

export default LSOverlay;